<template>
  <div>
    <v-card class="mx-auto elevation-0">
      <v-card-title>
        <v-toolbar
          flat
          v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.fundo"
        >
          <v-icon
            left
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone"
            >{{
              utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone.nome
            }}</v-icon
          >
          <v-toolbar-title
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.titulo"
            >{{ lang("logUtilizacao") }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <div class="flex-grow-2"></div>
          <v-btn
            v-on:click="abrirJanelaFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.pesquisar.atalho"
            v-on:shortkey="
              !desabilitado && permissaoListar() ? abrirJanelaFiltro() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.pesquisar"
            v-bind:disabled="desabilitado || !permissaoListar()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.pesquisar.icone }}</v-icon
            >{{ lang("pesquisar") }}</v-btn
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <!-- Listar -->
        <v-data-table
          class="elevation-0"
          height="70vh"
          fixed-header
          hide-default-footer
          v-bind:headers="titulo"
          v-bind:items="arrayLogUtilizacao.result"
          v-bind:items-per-page="itemPagina"
        >
          <!-- Campo -->
          <template v-slot:item.dataRegistro="{ item }">{{
            formatarDataHora(item.dataServidor)
          }}</template>
          <template v-slot:no-data>
            <v-alert v-bind:value="false" color="white" icon="info">{{
              lang("nenhumRegistroEncontrado")
            }}</v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-pagination
          v-model="page"
          v-bind="paginacao"
          v-bind:disabled="desabilitado || !permissaoListar()"
          v-on:input="pesquisar()"
        ></v-pagination>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- Pesquisar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgFiltrar"
      v-on:keydown.esc="validarFecharJanelaFiltro()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("pesquisar") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="validarFecharJanelaFiltro()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <vuetify-datetime
                  name="namFiltroDataHoraInicial"
                  v-model="model.filtroDataHoraInicial"
                  v-bind:label="lang('dataInicial')"
                  v-bind:options="utilImp.cnt.CONFIG.vuetify.dateTime1"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <vuetify-datetime
                  name="namFiltroDataHoraFinal"
                  v-model="model.filtroDataHoraFinal"
                  v-bind:label="lang('dataFinal')"
                  v-bind:options="utilImp.cnt.CONFIG.vuetify.dateTime1"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <selecionar-unidade
                  v-model="model.filtroUnidade"
                  v-bind:label="lang('unidade')"
                  v-bind:atualizar="dlgFiltrar"
                  v-bind:clearable="utilImp.cnt.BOLEANO.true"
                  v-bind:outlined="utilImp.cnt.BOLEANO.true"
                  v-bind:backgroundColor="
                    utilImp.cnt.CONFIG.select.backgroundColor
                  "
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <selecionar-usuario
                  v-model="model.filtroUsuario"
                  v-bind:label="lang('usuario')"
                  v-bind:atualizar="dlgFiltrar"
                  v-bind:clearable="true"
                  v-bind:outlined="true"
                  v-bind:backgroundColor="
                    utilImp.cnt.CONFIG.select.backgroundColor
                  "
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <selecionar-metodo
                  v-model="model.filtroMetodo"
                  v-bind:label="lang('metodo')"
                  v-bind:atualizar="dlgFiltrar"
                  v-bind:clearable="true"
                  v-bind:outlined="true"
                  v-on:clear="limparFiltroMetodo()"
                  v-bind:backgroundColor="
                    utilImp.cnt.CONFIG.select.backgroundColor
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- v-on:click:clear="limparFiltroConteudo()" eh executado apos o clearable ser clicado-->
                <v-text-field
                  clearable
                  maxlength="30"
                  name="namFiltroConteudo"
                  ref="refFiltroConteudo"
                  v-model="model.filtroConteudo"
                  v-bind:label="lang('conteudo')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                  v-on:keyup.enter="pesquisar()"
                  v-on:click:clear="limparFiltroConteudo()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" md="10" />
              <v-col cols="2" sm="2" md="2">
                <v-select
                  style="max-width:100%"
                  v-model="qtdItemPagina"
                  v-bind:items="arrayItemPagina"
                  v-bind:label="lang('itensPorPagina')"
                  v-bind="utilImp.cnt.CONFIG.select"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-btn
            v-on:click="limparFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.limpar.atalho"
            v-on:shortkey="limparFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.limpar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.limpar.icone }}</v-icon
            >{{ lang("limpar") }}</v-btn
          >
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="validarFecharJanelaFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="confirmar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.confirmar.atalho"
            v-on:shortkey="dlgFiltrar === true ? confirmar() : null"
            v-bind="utilImp.cnt.CONFIG.btn.confirmar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.confirmar.icone }}</v-icon
            >{{ lang("confirmar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "../../../util/Util.js";
import SelecionarUnidade from "@/views/proprietario/unidade/SelecionarUnidade.vue";
import SelecionarUsuario from "@/views/proprietario/usuario/SelecionarUsuario.vue";
import SelecionarMetodo from "@/views/sistema/logutilizacao/SelecionarMetodo.vue";

const ACAO_LISTAR = "acaoListar";
const ACAO_FECHAR_JANELA_FILTRO = "acaoFecharJanelaFiltro";
const ITEM_PAGINA = 5000; // v-data-table
const ARRAY_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.qtd;
const POSICAO_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.posicao.p1;

export default {
  components: {
    "selecionar-unidade": SelecionarUnidade,
    "selecionar-usuario": SelecionarUsuario,
    "selecionar-metodo": SelecionarMetodo
  },
  mixins: [util.mixUtil, util.mixAlerta],

  data: () => ({
    utilImp: util,
    dlgFiltrar: false,
    dlgSalvar: false,
    titulo: [],
    arrayLogUtilizacao: [],
    desabilitado: false,
    page: 1,
    itemPagina: ITEM_PAGINA,
    arrayItemPagina: ARRAY_ITEM_PAGINA,
    qtdItemPagina: ARRAY_ITEM_PAGINA[POSICAO_ITEM_PAGINA - 1],
    paginacao: { length: 1, totalVisible: 5, disabled: false },
    model: {
      filtroDataHoraInicial: "",
      filtroDataHoraFinal: "",
      filtroUnidade: null,
      filtroUsuario: null,
      filtroMetodo: "",
      filtroConteudo: ""
    },
    validacao: {
      filtroDataHoraInicial: "required",
      filtroDataHoraFinal: "required",
      filtroConteudo: "max:30"
    }
  }),

  computed: {},

  watch: {},

  created() {
    this.iniciar();
  },

  mounted() {
    this.model.filtroDataHoraInicial = this.utilImp.fnc.getFirstDateTimeMilliseconds();
    this.model.filtroDataHoraFinal = this.utilImp.fnc.getLastDateTimeMilliseconds();
    this.pesquisar();
  },

  methods: {
    iniciar() {
      // align: 'start' | 'center' | 'end'
      this.titulo = [
        {
          text: this.lang("usuario"),
          align: "left",
          value: "usuarioBEA.usuario",
          sortable: true
        },
        {
          text: this.lang("nome"),
          align: "left",
          value: "usuarioBEA.nomeCompleto",
          sortable: true
        },
        {
          text: this.lang("unidade"),
          align: "left",
          value: "usuarioBEA.unidadeBEA.unidade",
          sortable: false
        },
        {
          text: this.lang("classe"),
          align: "left",
          value: "classe",
          sortable: true
        },
        {
          text: this.lang("metodo"),
          align: "left",
          value: "metodo",
          sortable: true
        },
        {
          text: this.lang("conteudo"),
          align: "left",
          value: "conteudo",
          sortable: true
        },
        {
          text: this.lang("dataRegistro"),
          align: "center",
          value: "dataRegistro",
          sortable: false
        }
      ];
    },

    confirmar() {
      this.limparPaginador();
      this.pesquisar();
    },

    pesquisar() {
      if (this.dlgFiltrar) {
        // Caso a janela filtrar esteja aberta, ocorrera a validacao.
        this.validarPesquisar(ACAO_LISTAR);
      } else {
        this.listar();
      }
    },

    async validarPesquisar(acao) {
      let result = "";
      result = await util.val.validate(
        this.model.filtroDataHoraInicial,
        this.validacao.filtroDataHoraInicial
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("dataInicial"))
        );
        return;
      }
      result = await util.val.validate(
        this.model.filtroDataHoraFinal,
        this.validacao.filtroDataHoraFinal
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("dataFinal"))
        );
        return;
      }
      result = await util.val.validate(
        this.model.filtroConteudo,
        this.validacao.filtroConteudo
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("conteudo"))
        );
        return;
      }
      // Direcionar para listar
      if (acao === ACAO_LISTAR) {
        this.listar();
      }
      // Direcionar para fechar a janela filtro
      if (acao === ACAO_FECHAR_JANELA_FILTRO) {
        this.fecharJanelaFiltro();
      }
    },

    listar() {
      this.desabilitar(true);
      this.limparLista();
      this.showLoader();
      util.srv.sistema
        .listarLogUtilizacao(
          this.model.filtroUnidade,
          this.model.filtroUsuario,
          this.model.filtroDataHoraInicial,
          this.model.filtroDataHoraFinal,
          this.model.filtroMetodo !== "null" ? this.model.filtroMetodo : "",
          this.model.filtroConteudo,
          this.pagina(),
          this.limite()
        )
        .then(response => {
          this.distribuir(response.data);
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.fecharJanelaFiltro();
          this.hideLoader();
        });
    },

    distribuir(arrayRegistro) {
      if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        if (arrayRegistro.result !== null) {
          this.arrayLogUtilizacao = arrayRegistro;
          this.paginador();
        } else {
          // this.alertInfo(this.lang("nenhumRegistroEncontrado"));
        }
      } else {
        this.alertWarning(this.langResultCode(arrayRegistro.resultCode));
      }
    },

    abrirJanelaFiltro() {
      this.dlgFiltrar = true;
      this.setFocusConteudo();
    },

    setFocusConteudo() {
      setTimeout(() => {
        this.$refs.refFiltroConteudo.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    fecharJanelaFiltro() {
      if (!this.desabilitado) {
        this.dlgFiltrar = false;
      }
    },
    /* Metodo responsavel por fechar a janela de pesquisa somente se os filtros forem validos */
    /* Necessario para que o paginador não execute a consulta com parametros invalidos */
    validarFecharJanelaFiltro() {
      this.validarPesquisar(ACAO_FECHAR_JANELA_FILTRO);
    },

    limparLista() {
      this.arrayLogUtilizacao = [];
    },
    limparFiltro() {
      this.model.filtroDataHoraInicial = this.utilImp.fnc.getFirstDateTimeMilliseconds();
      this.model.filtroDataHoraFinal = this.utilImp.fnc.getLastDateTimeMilliseconds();
      this.model.filtroUnidade = null;
      this.model.filtroUsuario = null;
      this.model.filtroMetodo = "";
      this.model.filtroConteudo = "";
    },
    limparPaginador() {
      this.page = 1;
      this.paginacao.length = 1;
    },
    /* Utilizado para limpar após o uso do clearable*/
    limparFiltroMetodo() {
      setTimeout(() => {
        this.model.filtroMetodo = "";
      }, 300);
    },
    /* Utilizado para limpar após o uso do clearable*/
    limparFiltroConteudo() {
      setTimeout(() => {
        this.model.filtroConteudo = "";
      }, 300);
    },

    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },

    iconeAtivo: function(valor) {
      return valor === util.cnt.BOLEANO.sim
        ? util.cnt.CONFIG.icon.sim
        : util.cnt.CONFIG.icon.nao;
    },

    paginador() {
      let contRegistro = this.arrayLogUtilizacao.result.length;
      if (contRegistro >= this.qtdItemPagina) {
        this.paginacao.length = this.page + 1;
      }
    },

    pagina: function() {
      return this.page - 1;
    },
    limite: function() {
      return this.qtdItemPagina;
    },

    permissaoListar() {
      return this.verificarPermissao(util.prm.P_1032);
    }
  }
};
</script>
