<template>
  <div>
    <v-select
      item-value="metodo"
      item-text="metodo"
      v-model="id"
      v-bind:value="cmpId"
      v-bind:items="items"
      v-bind:label="label"
      v-bind:clearable="clearable"
      v-bind:outlined="outlined"
      v-bind:backgroundColor="backgroundColor"
      v-on:click:clear="$emit('clear')"
    ></v-select>
  </div>
</template>

<!--
-- Duas maneiras de utilizar no componente pai:
-Com uma variavel exclusiva que desarma apos cada atualizacao
<BuscarUnidade v-model="itemEditado.unidadeBEA.idUnidade" v-bind:atualizar="atualizarBuscarUnidade" v-on:atualizar="atualizarBuscarUnidade = $event"/>
-Utilizando o controle do dialogbox para atualizar a lista
<BuscarUnidade v-model="itemEditado.unidadeBEA.idUnidade" v-bind:atualizar="dlgSalvar"/>
-->

<script>
import util from "../../../util/Util.js";

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],
  model: { prop: "value", event: "input" },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    atualizar: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    id: null
  }),
  computed: {
    cmpId: function() {
      const THIS = this;
      return (THIS.id = this.value !== "" ? this.value : null);
    }
  },
  watch: {
    id() {
      this.$emit("input", this.id);
    },
    atualizar() {
      this.limpar();
      if (this.atualizar) {
        this.listar();
      }
    }
  },
  // Ao clicar em incluir do componente pai, este componente ainda nao existe. Por isso listar no created
  created() {
    this.listar();
  },
  methods: {
    listar() {
      util.srv.sistema
        .listarMetodos()
        .then(response => {
          this.items =
            response.data.result !== null ? response.data.result : [];
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desarmar();
        });
    },
    limpar() {
      this.items = [];
    },
    /* Desarmar a acao de atualizar do componente pai. */
    desarmar() {
      this.$emit("atualizar", false);
    }
  }
};
</script>
